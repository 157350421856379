<template>
  <SkeletonComponentLoader>
    <section v-if="!statisticsLoaded" class="statistic-controller-skeleton">
      <SkeletonBaseBackground
        :height="sizes.container.height"
        :border-radius="0"
        class="statistic-controller-skeleton__background"
      >
        <div v-for="i in 2" :key="i" class="statistic-controller-skeleton__container">
          <SkeletonBaseForeground
            v-for="j in 2"
            :key="j"
            :width="sizes.item.width"
            :height="sizes.item.height"
            :border-radius="sizes.item.border"
          />
        </div>
      </SkeletonBaseBackground>
    </section>
    <slot v-else></slot>
  </SkeletonComponentLoader>
</template>

<script setup lang="ts">
import { useStatisticStore } from '~/store/statistic/statistic.store';

const statisticStore = useStatisticStore();

const { isLoaded: statisticsLoaded } = storeToRefs(statisticStore);

const changeMD = GlobalUtils.Media.changeByMedia('md');
const changeXL = GlobalUtils.Media.changeByMedia('xl');

const sizes = computed(() => ({
  container: {
    height: changeXL(96, changeMD(63, 96)),
    width: changeXL(240, 168),
  },
  item: {
    border: changeXL(12, 6),
    height: changeXL(40, 24),
    width: changeXL(218, 160),
  },
}));
</script>

<style scoped lang="scss" src="./StatisticController.skeleton.scss" />
